
import Vue from 'vue'

export default Vue.extend({
  name: 'RewardIcon',
  props: {
    icon: {
      type: String,
      default: 'pearl'
    }
  },
  computed: {
    getIcon () {
      return `/assets/2025/${this.icon.replace(/_/g, '-').toLowerCase()}.png`
    },
    getIconAlt () {
      return this.icon.replace(/_/g, '').toUpperCase()
    }
  }
})
