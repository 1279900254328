<template>
  <div class="account">
    <div class="account__info mb-5 px-xl pt-8.5 pb-5 flex bg-black flex-wrap rounded-20px">
      <div class="w-full lg:w-1/2">
        <div class="flex items-center" v-if="currentTier !== 'gold'">
          <div class="w-1/3">
            <reward-icon class="w-1/8" :icon="currentTier"/>
          </div>
          <div class="account__grade w-2/3">
            <p class="account__head pl-5" :style="`color: #${currentTierDetails.hex_color}`">
              {{ (currentTier || '').replace(/_/g, ' ').toUpperCase() }}
            </p>
          </div>
        </div>
        <div class="flex justify-center align-center" v-else>
          <p class="text-center text-white pt-10 pb-6">
            How to qualify for even more benefits:<br>
            <router-link :to="localizedRoute('/rewards-program/reward-levels')" class="underline">Learn about our reward program</router-link>
          </p>
        </div>
        <div class="mt-5 text-center">
          <p class="account__title uppercase">
            {{ $t('Avg. monthly spend last quarter') }}
          </p>
          <p class="account__head">
            <span v-if="lastQtrSpend">{{ lastQtrSpend | price | numberDisplay }}</span>
          </p>
        </div>
      </div>
      <div class="w-full lg:w-1/2 mb-10 lg:mb-0 flex flex-col text-right">
        <div class="account__name font-serif tracking-tiny text-xl leading-h4 text-white font-black">
          {{ currentUser.firstname }} {{ currentUser.lastname }}
        </div>
        <div class="account__email text-white text-heading-page leading-sm tracking-normal">
          {{ currentUser.email }}
        </div>
        <div class="text-white mt-8 flex">
          <div class="w-1/2"></div>
          <div class="w-1/2 text-center">
              <span :class="`bg-blue text-white text-md font-medium me-2 px-10 py-0.5 rounded-full w-full block points-bg--${ (currentTier || '').replace(/_/g, '-').toLowerCase() } font-bold`">
                {{ getRcoLoyalty.balance }}
              </span>
            <span class="text-md">Loyalty Points</span>
          </div>
        </div>
        <div class="mt-auto">
          <router-link :to="localizedRoute({name: 'my-profile'})"
                       class="text-sm leading-none tracking-lg uppercase no-underline hover:underline text-white">
            {{ $t('Edit Contact Information') }}
          </router-link>
        </div>
      </div>
    </div>
    <!--    <account-next-level v-if="nextTierExists"/>-->
    <!--    <account-rewards />-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
// import AccountNextLevel from 'theme/components/theme/blocks/Account/AccountNextLevel'
// import AccountRewards from 'theme/components/theme/blocks/Account/AccountRewards'
import EarnedPoints from 'theme/mixins/blocks/EarnedPoints'
import {localizedRoute} from '@vue-storefront/core/lib/multistore'
import RewardIcon from "theme/components/core/blocks/Rewards/RewardIcon.vue";

export default {
  name: 'AccountInfo',
  components: {
    RewardIcon
    // AccountNextLevel,
    // AccountRewards
  },
  mixins: [
    EarnedPoints
  ],
  data() {
    return {
      rewardPoints: [],
      // currentUser: Object.assign({}, this.$store.state.user.current)
    }
  },
  computed: {
    ...mapGetters({
      dashboardInfo: 'loyalty/getDashboard'
    }),
    lastQtrSpend() {
      return this.dashboardInfo ? this.dashboardInfo.last_qtr_avg_spend : 0
    },
    currentTier() {
      return this.dashboardInfo ? this.dashboardInfo.current_tier : ''
    },
    currentTierDetails () {
      return (((this.dashboardInfo || {}).tier_table || {}).rco || []).find(x => x.common_name === this.currentTier) || {}
    },
    nextTierExists() {
      return this.dashboardInfo ? !!this.dashboardInfo.next_tier : false
    },
    getRcoLoyalty() {
      return this.rewardPoints['rco'] || {}
    }
  },
  async beforeMount() {
    this.$bus.$on('user-after-loggedin', this.onLoggedIn)
    this.rewardPoints = await this.getRewards()
  },
  beforeDestroy() {
    this.$bus.$off('user-after-loggedin', this.onLoggedIn)
  },
  methods: {
    localizedRoute,
    onLoggedIn() {
      this.currentUser = Object.assign({}, this.$store.state.user.current)
    }
  }
}
</script>

<style lang="scss" scoped>
.account {
  .points-bg {
    background-size: cover;
    &--gold {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/gold.jpg');
    }

    &--sapphire {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/sapphire.jpg');
    }

    &--pearl {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/pearl.jpg');
    }

    &--emerald {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/emerald.jpg');
    }

    &--ruby {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/ruby.jpg');
    }

    &--diamond {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/diamond.jpg');
    }

    &--black-diamond {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/black-diamond.png');
    }
  }

  &__info {
    min-height: 218px;
  }

  &__grade {
    //margin-left: 80px;
    @screen md {
      //margin-left: 125px;
    }
  }

  &__level {
    background-image: url('/assets/sprite-rewards-level-hd.png');
    background-size: cover;
    height: 80px;
    width: 80px;

    @screen md {
      height: 125px;
      width: 125px;
    }

    &--gold {
      background-image: url('/assets/sprite-rewards-gold.png');
    }

    &--sapphire {
      background-position: 0 -80px;

      @screen md {
        background-position: 0 -125px;
      }
    }

    &--pearl {
      background-position: 0 0;

      @screen md {
        background-position: 0 0;
      }
    }

    &--emerald {
      background-position: 0 -160px;

      @screen md {
        background-position: 0 -250px;
      }
    }

    &--ruby {
      background-position: 0 -240px;

      @screen md {
        background-position: 0 -375px;
      }
    }

    &--diamond {
      background-position: 0 -320px;

      @screen md {
        background-position: 0 -500px;
      }
    }

    &--black_diamond {
      background-position: 0 -400px;

      @screen md {
        background-position: 0 -625px;
      }
    }
  }

  &__head {
    @apply text-white font-black text-lg leading-none tracking-md uppercase;
  }

  &__title {
    @apply text-sm leading-tight tracking-lg text-white uppercase;
    margin-bottom: 12px;
  }
}

</style>
