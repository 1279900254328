<template>
  <div class="-mx-sm">
    <h1 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{ $t('Dashboard') }}
    </h1>
    <div class="flex flex-wrap">
      <div class="w-full">
        <account-info class="mb-5" />
        <div class="md:px-8.5 px-5 md:py-xl py-5 md:bg-white md:mb-5 rounded-20px">
          <div class="flex items-start">
            <h2 class="text-primary font-normal text-mid leading-none italic font-serif tracking-tiny mb-9.5">
              {{ $t('Address Book') }}
            </h2>
<!--            <router-link :to="localizedRoute({name: 'my-address'})" class="ml-auto text-sm leading-none tracking-lg uppercase underline hover:no-underline">
              {{ $t('Manage Addresses') }}
            </router-link>-->
          </div>
          <div class="flex">
            <div class="w-full md:w-1/2 md:pr-8 pr-2">
              <my-address
                :address="billingAddress"
                is-billing
              />
            </div>
            <div class="w-full md:w-1/2 md:pl-8 pl-2">
              <my-address
                :address="shippingAddress"
                is-shipping
              />
            </div>
          </div>
        </div>
<!--         <div class="md:px-8.5 px-5 md:py-xl py-5 md:bg-white md:mb-5">
          <h3 class="text-primary font-normal text-mid leading-none italic font-serif tracking-tiny mb-9.5">
            {{ $t('Newsletters') }}
          </h3>
          <p class="leading-h5 text-above-md text-dark tracking-xs" v-if="!currentUser.isSubscribed">
            {{ $t('You are currently not subscribed to any newsletter.') }}
          </p>
          <p class="leading-h5 text-above-md text-dark tracking-xs" v-else>
            {{ $t('You are currently subscribed to \'General Subscription\'.') }}
          </p>
          <p>
            <router-link :to="localizedRoute({name: 'my-newsletter'})" class="text-sm leading-none tracking-lg uppercase underline hover:no-underline">
              {{ $t('Edit') }}
            </router-link>
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import MyOrders from '@vue-storefront/core/compatibility/components/blocks/MyAccount/MyOrders'
import AccountInfo from 'theme/components/core/blocks/MyAccount/AccountInfo'
import AddressBook from 'theme/mixins/blocks/AddressBook'
import MyAddress from './MyAddress'

export default {
  name: 'MyDashboard',
  components: {
    MyAddress,
    AccountInfo
  },
  mixins: [AddressBook, MyOrders]
}
</script>
